import React from 'react';

import { useCompleteFragment } from '@eluve/apollo-client';
import { HStack, VStack, textStyles } from '@eluve/components';
import { formatDateToReadable, formatTo12HourTime } from '@eluve/date-utils';
import {
  baseAppointmentInfoFragment,
  latestHumanSOAPNoteFragment,
  useAmendments,
  useAppointmentCompletedAt,
  useAppointmentId,
  useAppointmentSignedDetails,
  useAppointmentStartedAt,
} from '@eluve/frontend-appointment-hooks';
import { useUserName } from '@eluve/smart-blocks';
import { getUserName } from '@eluve/utils';

type VersionHistoryEvent = {
  time: string;
  modifiedBy: string;
  action:
    | 'started the session'
    | 'ended the session'
    | 'edited the chart'
    | 'signed the chart'
    | 'amended'
    | 'signed with amendments'
    | 'last edited the chart';
};

type VersionHistory = {
  [key: string]: VersionHistoryEvent[];
};

export const VersionHistorySidebar: React.FC = () => {
  const appointmentId = useAppointmentId();
  const appointmentDetails = useCompleteFragment({
    fragment: baseAppointmentInfoFragment,
    key: { id: appointmentId },
  });
  const latestHumanOutput = useCompleteFragment({
    fragment: latestHumanSOAPNoteFragment,
    key: { id: appointmentId },
  });
  const humanOutputUpdatedAt =
    latestHumanOutput.humanOutputs[0]?.output.updatedAt;

  const { amendments } = useAmendments(appointmentId, { submittedOnly: true });
  const completedAt = useAppointmentCompletedAt();
  const startedAt = useAppointmentStartedAt();
  const signedDetails = useAppointmentSignedDetails();
  const appointmentUser = useUserName(appointmentDetails?.userId ?? '');
  const { signedAt, signedBy } = signedDetails;

  const versionsByDate: VersionHistory = {};

  if (humanOutputUpdatedAt) {
    versionsByDate[formatDateToReadable(humanOutputUpdatedAt)] ??= [];
    versionsByDate[formatDateToReadable(humanOutputUpdatedAt)]!.push({
      time: humanOutputUpdatedAt,
      action: 'last edited the chart',
      modifiedBy: appointmentUser,
    });
  }

  if (completedAt) {
    versionsByDate[formatDateToReadable(completedAt)] ??= [];
    versionsByDate[formatDateToReadable(completedAt)]!.push({
      time: completedAt,
      action: 'ended the session',
      modifiedBy: appointmentUser,
    });
  }

  if (startedAt) {
    versionsByDate[formatDateToReadable(startedAt)] ??= [];
    versionsByDate[formatDateToReadable(startedAt)]!.push({
      time: startedAt,
      action: 'started the session',
      modifiedBy: appointmentUser,
    });
  }

  if (signedAt && signedBy) {
    versionsByDate[formatDateToReadable(signedAt)] ??= [];
    versionsByDate[formatDateToReadable(signedAt)]!.push({
      time: signedAt,
      action: 'signed the chart',
      modifiedBy: signedBy,
    });
  }

  amendments.forEach((amendment) => {
    versionsByDate[formatDateToReadable(amendment.createdAt)] ??= [];
    versionsByDate[formatDateToReadable(amendment.createdAt)]!.push({
      time: amendment.createdAt,
      action: 'amended',
      modifiedBy: getUserName({
        email: amendment.user?.email,
        firstName: amendment.user?.firstName,
        lastName: amendment.user?.lastName,
      }),
    });
    if (amendment.submittedAt) {
      versionsByDate[formatDateToReadable(amendment.submittedAt)] ??= [];
      versionsByDate[formatDateToReadable(amendment.submittedAt)]!.push({
        time: amendment.submittedAt,
        action: 'signed with amendments',
        modifiedBy: getUserName({
          email: amendment.user?.email,
          firstName: amendment.user?.firstName,
          lastName: amendment.user?.lastName,
        }),
      });
    }
  });

  return (
    <VStack gap={8} className="p-6">
      {Object.keys(versionsByDate)
        .sort((a, b) => new Date(b).getTime() - new Date(a).getTime())
        .map((date, i) => {
          const events = versionsByDate[date]!;
          return (
            <VStack key={i} gap={3}>
              <span
                className={textStyles.body({
                  color: 'secondary',
                  size: 'l',
                  weight: 'semibold',
                })}
              >
                {date}
              </span>
              {events
                .sort(
                  (a, b) =>
                    new Date(b.time).getTime() - new Date(a.time).getTime(),
                )
                .map((event, i) => (
                  <VStack key={i} gap={1}>
                    <span
                      className={textStyles.body({
                        color: 'secondary',
                        size: 's',
                        weight: 'semibold',
                      })}
                    >
                      {formatTo12HourTime(event.time)}
                    </span>
                    <HStack gap={1}>
                      <span
                        className={textStyles.body({
                          color: 'primary',
                          size: 'm',
                          weight: 'semibold',
                        })}
                      >
                        {event.modifiedBy}
                      </span>
                      <span
                        className={textStyles.body({
                          color: 'tertiary',
                          size: 'm',
                          weight: 'semibold',
                        })}
                      >
                        {event.action}
                      </span>
                    </HStack>
                  </VStack>
                ))}
            </VStack>
          );
        })}
    </VStack>
  );
};
