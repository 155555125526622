import { Slot } from '@radix-ui/react-slot';
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useMedia } from 'react-use';
import { VariantProps } from 'tailwind-variants';

import { tv } from './cn';
import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
} from './sheet';

type DetailsSidebarContext = {
  alwaysOverlay: boolean;
  open: boolean;
  setOpen: (open: boolean) => void;
  toggleSidebar: () => void;
  variant: 'fixed' | 'overlay';
};

const DetailsSidebarContext = createContext<DetailsSidebarContext | null>(null);

function useDetailsSidebar() {
  const context = useContext(DetailsSidebarContext);
  if (!context) {
    throw new Error(
      'useDetailsSidebar must be used within a DetailsSidebarProvider.',
    );
  }

  return context;
}

function DetailsSidebarProvider({
  children,
  overlayOnly = false,
}: {
  children: ReactNode;
  overlayOnly?: boolean;
}) {
  const [open, setOpen] = useState(false);
  const toggleSidebar = useCallback(() => {
    setOpen((open) => !open);
  }, [setOpen]);

  const canBeFixed = useMedia('(min-width: 64rem)');
  const variant = overlayOnly || !canBeFixed ? 'overlay' : 'fixed';

  useEffect(() => {
    if (variant === 'fixed') {
      setOpen(false);
    }
  }, [variant]);

  const contextValue = useMemo<DetailsSidebarContext>(
    () => ({
      alwaysOverlay: overlayOnly,
      open,
      setOpen,
      toggleSidebar,
      variant,
    }),
    [overlayOnly, open, setOpen, toggleSidebar, variant],
  );

  return (
    <DetailsSidebarContext.Provider value={contextValue}>
      {children}
    </DetailsSidebarContext.Provider>
  );
}

function DetailsSidebarTrigger({ children }: { children: ReactNode }) {
  const { toggleSidebar, variant } = useDetailsSidebar();

  if (variant === 'fixed') {
    return null;
  }

  return <Slot onClick={() => toggleSidebar()}>{children}</Slot>;
}

const detailsSidebarStyles = tv({
  base: '',
  variants: {
    size: {
      sm: 'w-80',
      md: 'w-112',
      lg: 'w-full md:w-144',
    },
  },
  defaultVariants: {
    size: 'sm',
  },
});

type DetailsSidebarStyleProps = VariantProps<typeof detailsSidebarStyles>;

/**
 * A component that renders a sidebar for displaying details or additional functionality.
 *
 * @param children - The content to be displayed inside the sidebar.
 * @param description - The optional subtext displayed below the title when in an overlay.
 * @param size - The size of the sidebar when in an overlay.
 * @param title - The text displayed on top of the sidebar when in an overlay.
 *
 * @remark
 * The sidebar is designed to be used within a `DetailsSidebarProvider` context.
 * The provider has an optional `overlayOnly` prop that determines whether the sidebar should only be displayed in overlay mode.
 * By default it will be presented inline on the right side when the screen is large enough.
 */
function DetailsSidebar({
  children,
  description,
  size,
  title,
}: {
  /**
   * The content to be displayed inside the sidebar.
   */
  children: ReactNode;
  /**
   * The optional subtext displayed below the title when in an overlay.
   */
  description?: string;
  /**
   * The size of the sidebar when in an overlay.
   */
  size?: DetailsSidebarStyleProps['size'];
  /**
   * The text displayed on top of the sidebar when in an overlay.
   */
  title: string;
}) {
  const { alwaysOverlay, open: isOpen, setOpen, variant } = useDetailsSidebar();

  if (variant === 'fixed') {
    return (
      <aside className="col-start-2 row-span-2 row-start-2 h-full w-80 self-stretch overflow-auto border-l border-borderPrimary">
        {children}
      </aside>
    );
  }

  return (
    <Sheet modal={!alwaysOverlay} open={isOpen} onOpenChange={setOpen}>
      <SheetContent
        className={detailsSidebarStyles({ size })}
        hasOverlay={!alwaysOverlay}
        onInteractOutside={(event) => {
          if (alwaysOverlay) {
            event.preventDefault();
          }
        }}
      >
        <SheetClose />
        <SheetHeader>
          <SheetTitle>{title}</SheetTitle>
          {description && <SheetDescription>{description}</SheetDescription>}
        </SheetHeader>
        {children}
      </SheetContent>
    </Sheet>
  );
}

export {
  DetailsSidebar,
  DetailsSidebarProvider,
  DetailsSidebarTrigger,
  useDetailsSidebar,
};
