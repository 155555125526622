import { useCompleteFragment } from '@eluve/apollo-client';
import { graphql } from '@eluve/graphql.tada';
import { getUserName } from '@eluve/utils';

import { useAppointmentId } from './appointment.context';

const fragment = graphql(`
  fragment AppointmentSignedAt on Appointments {
    __typename
    id
    doctor_interaction {
      __typename
      appointmentId
      noteSignedAt
      user {
        __typename
        id
        lastName
        firstName
        email
      }
    }
  }
`);

export const useAppointmentSignedDetails = () => {
  const id = useAppointmentId();

  const data = useCompleteFragment({
    fragment,
    key: {
      id,
    },
  });

  const signedAt = data.doctor_interaction?.noteSignedAt ?? null;

  return {
    signedAt,
    signedBy: signedAt
      ? getUserName({
          email: data.doctor_interaction?.user?.email,
          firstName: data.doctor_interaction?.user?.firstName,
          lastName: data.doctor_interaction?.user?.lastName,
        })
      : null,
  };
};
