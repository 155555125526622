import {
  Divider,
  HStack,
  Label,
  SectionCard,
  SectionCardContent,
  SectionCardHeader,
  VStack,
} from '@eluve/components';
import { useAppointmentId } from '@eluve/frontend-appointment-hooks';
import { StartVirtualIntake } from '@eluve/frontend-feature-avatar';
import { useTenantIdFromParams } from '@eluve/session-helpers';

import { UploadAudioFileButton } from '../UploadAudioFileButton';

import { AddToTranscript } from './AddToTranscript';
import { LatestAppointmentTemplate } from './LatestAppointmentTemplate';
import { OpenInAdmin } from './OpenInAdmin';
import { RegenerateSummary } from './RegenerateSummary';
import { SimulateDegradedTranscription } from './SimulateDegradedTranscription';

interface EluveAdminPanelProps {
  readonlyMode?: boolean;
}
export const EluveAdminPanel: React.FC<EluveAdminPanelProps> = ({
  readonlyMode = false,
}) => {
  const appointmentId = useAppointmentId();
  const tenantId = useTenantIdFromParams();

  return (
    <SectionCard>
      <SectionCardHeader>Eluve Admin Panel</SectionCardHeader>
      <SectionCardContent>
        {readonlyMode ? (
          <HStack wFull align="start" gap={6}>
            <VStack align="stretch" className="flex-1">
              <LatestAppointmentTemplate appointmentId={appointmentId} />
            </VStack>
            <VStack align="stretch" className="flex-1">
              <OpenInAdmin appointmentId={appointmentId} tenantId={tenantId!} />
            </VStack>
          </HStack>
        ) : (
          <HStack wFull align="start" gap={6}>
            <VStack align="stretch" className="flex-1">
              <AddToTranscript appointmentId={appointmentId} />
              <Divider />
              <VStack>
                <Label className="px-1">Upload Audio File</Label>
                <UploadAudioFileButton appointmentId={appointmentId} />
              </VStack>
              <Divider />
              <LatestAppointmentTemplate appointmentId={appointmentId} />
            </VStack>
            <VStack align="stretch" className="flex-1">
              <Label>Misc. Actions</Label>
              <OpenInAdmin appointmentId={appointmentId} tenantId={tenantId!} />
              <RegenerateSummary
                appointmentId={appointmentId}
                tenantId={tenantId!}
              />
              <Divider />
              <SimulateDegradedTranscription />
              <Divider />
              <StartVirtualIntake
                tenantId={tenantId!}
                appointmentId={appointmentId}
              />
            </VStack>
          </HStack>
        )}
      </SectionCardContent>
    </SectionCard>
  );
};
