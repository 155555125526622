import max from 'date-fns/max';
import { useMemo } from 'react';
import { useUnmount } from 'react-use';
import { create } from 'zustand';

import { useCompleteFragment } from '@eluve/apollo-client';
import { HStack, Icon, textStyles } from '@eluve/components';
import { getFormattedDateInMDYWithTimePeriod } from '@eluve/date-utils';
import {
  useAmendments,
  useAppointmentId,
} from '@eluve/frontend-appointment-hooks';
import { graphql } from '@eluve/graphql.tada';
import { getUserName } from '@eluve/utils';

const lastUpdatedAtDoctorInteractionNotes = graphql(`
  fragment LastUpdatedDoctorInteractionNotes on AppointmentDoctorInteractions {
    __typename
    updatedAt
    noteSignedAt
    user {
      id
      __typename
      firstName
      lastName
      email
    }
  }
`);

const lastUpdatedAtHumanOutputs = graphql(`
  fragment LatestHumanSOAPNote on Appointments @_unmask {
    __typename
    humanOutputs(
      where: { output: { outputType: { _eq: SOAP_NOTE } } }
      limit: 1
      orderBy: { output: { updatedAt: DESC } }
    ) {
      __typename
      appointmentId
      humanOutputId
      output {
        __typename
        id
        updatedAt
      }
    }
  }
`);

export const pendingUnsavedChangesStore = create(() => false);

export const AppointmentLastSaved: React.FC<{
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}> = ({ onClick }) => {
  const appointmentId = useAppointmentId();
  const pendingUnsavedChanges = pendingUnsavedChangesStore();

  useUnmount(() => {
    pendingUnsavedChangesStore.setState(false);
  });

  const additionalNotes = useCompleteFragment({
    fragment: lastUpdatedAtDoctorInteractionNotes,
    key: { appointmentId },
    strict: false,
  });

  const summary = useCompleteFragment({
    fragment: lastUpdatedAtHumanOutputs,
    key: { id: appointmentId },
    strict: false,
  });

  const { amendments } = useAmendments(appointmentId);
  const lastSavedAt = useMemo(() => {
    const additionalNotesUpdatedAtDate = additionalNotes?.updatedAt
      ? new Date(additionalNotes.updatedAt)
      : undefined;

    const summaryUpdatedAt = summary?.humanOutputs?.[0]?.output?.updatedAt;

    const summaryUpdatedAtDate = summaryUpdatedAt
      ? new Date(summaryUpdatedAt)
      : undefined;

    const amendmentsUpdatedAt: Date[] = [];
    amendments.forEach((a) => {
      amendmentsUpdatedAt.push(new Date(a.updatedAt));
      a.chart_amendments.forEach((ca) =>
        amendmentsUpdatedAt.push(new Date(ca.updatedAt)),
      );
    });

    const updatedDates = [
      additionalNotesUpdatedAtDate,
      summaryUpdatedAtDate,
      ...amendmentsUpdatedAt,
    ].filter(Boolean) as Date[];

    return updatedDates.length > 0 ? max(updatedDates) : undefined;
  }, [additionalNotes, summary, amendments]);

  const { amendments: submittedAmendments } = useAmendments(appointmentId, {
    submittedOnly: true,
  });
  const lastSubmittedAmendment =
    submittedAmendments[submittedAmendments.length - 1];

  const getLastSavedMessage = () => {
    if (lastSubmittedAmendment?.submittedAt) {
      return `Last amended by ${getUserName(lastSubmittedAmendment.user)} at ${getFormattedDateInMDYWithTimePeriod(new Date(lastSubmittedAmendment.submittedAt))}`;
    }
    if (additionalNotes?.noteSignedAt) {
      return `Last signed by ${getUserName(additionalNotes.user)} at ${getFormattedDateInMDYWithTimePeriod(new Date(additionalNotes.noteSignedAt))}`;
    }
    if (lastSavedAt) {
      return `Last saved at ${getFormattedDateInMDYWithTimePeriod(lastSavedAt)}`;
    }
    if (pendingUnsavedChanges) {
      return 'Saving...';
    }
    return '';
  };

  const message = getLastSavedMessage();
  if (!message) {
    return null;
  }

  return (
    <div className={onClick ? 'cursor-pointer' : ''} onClick={onClick}>
      <HStack>
        <Icon
          name={pendingUnsavedChanges ? 'Loader2Icon' : 'History'}
          size="xs"
          className={pendingUnsavedChanges ? 'animate-spin text-brand-8' : ''}
        />
        <span
          className={`${textStyles.body({ color: 'tertiary', size: 'm' })} hidden sm:inline`}
        >
          {message}
        </span>
      </HStack>
    </div>
  );
};
