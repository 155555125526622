import { useMutation } from '@apollo/client';
import { UserIcon } from 'lucide-react';

import { cacheUtils, useCompleteFragment } from '@eluve/apollo-client';
import {
  Box,
  DetailsSidebar,
  H4,
  SingleDateSelector,
  TimeRangeSelect,
  VStack,
  toast,
} from '@eluve/components';
import {
  AppointmentPatientIdFragment,
  patientFragment,
  useAppointmentStatus,
  useIsAppointmentReadonly,
} from '@eluve/frontend-appointment-hooks';
import { SessionTypeSelector } from '@eluve/frontend-feature-appointment';
import { VariablesOf, graphql } from '@eluve/graphql.tada';
import {
  FeatureFlaggedComponent,
  LocationSelector,
  PatientSelector,
  useIsFeatureFlagEnabledForLocation,
  useUserName,
} from '@eluve/smart-blocks';

import { EhrDataImporter } from '../../components/EhrDataImporter';

interface AppointmentDetailsProps {
  tenantId: string;
  appointmentId: string;
}

export const AppointmentDetailsFragment = graphql(`
  fragment AppointmentDetails on Appointments @_unmask {
    __typename
    id
    startedAt
    startDate
    endDate
    locationId
    patientId
    userId
    inputLanguage
    outputLanguage
  }
`);

const UpdateAppointmentDetailsMutation = graphql(
  `
    mutation UpdateAppointmentDetails(
      $tenantId: uuid!
      $id: uuid!
      $input: AppointmentsSetInput!
    ) {
      updateAppointmentsByPk(
        pkColumns: { tenantId: $tenantId, id: $id }
        _set: $input
      ) {
        ...AppointmentDetails
        patient {
          ...patient
        }
      }
    }
  `,
  [AppointmentDetailsFragment, patientFragment],
);

const toDate = (date?: string | null) => (date ? new Date(date) : undefined);
const dateToISOString = (date?: Date | null) =>
  date ? date.toISOString() : null;

export const AppointmentDetails: React.FC<AppointmentDetailsProps> = ({
  tenantId,
  appointmentId,
}) => {
  const isAppointmentReadonly = useIsAppointmentReadonly();

  const appointmentDetails = useCompleteFragment({
    fragment: AppointmentDetailsFragment,
    key: {
      id: appointmentId,
    },
  });

  const isAppointmentDetailsReadonly = useIsFeatureFlagEnabledForLocation(
    'APPOINTMENT_DETAILS_READONLY',
    appointmentDetails.locationId ?? '',
  );

  const isReadonly = isAppointmentReadonly || isAppointmentDetailsReadonly;

  const username = useUserName(appointmentDetails?.userId ?? '');
  const status = useAppointmentStatus();

  const [updateAppointmentDetails] = useMutation(
    UpdateAppointmentDetailsMutation,
    {
      optimisticResponse: ({ input, id }) => {
        return {
          ___typename: 'mutation_root' as const,
          updateAppointmentsByPk: {
            ...appointmentDetails,
            ...input,
            startDate: input.startDate ?? appointmentDetails.startDate,
            inputLanguage: appointmentDetails.inputLanguage ?? '',
            outputLanguage: appointmentDetails.outputLanguage ?? '',
            id,
            patient: null,
          },
        };
      },
      onError: () => {
        toast.error('Unable to update appointment details');
      },
      update: (_, { data }) => {
        const patientId = data?.updateAppointmentsByPk?.patientId;
        if (!patientId) {
          return;
        }

        cacheUtils.updateFragment(
          {
            fragment: AppointmentPatientIdFragment,
            key: { id: appointmentId },
          },
          (existing) => {
            if (!existing) {
              return;
            }
            return {
              ...existing,
              patientId,
            };
          },
        );
      },
    },
  );

  const handleChange = (
    input: VariablesOf<typeof UpdateAppointmentDetailsMutation>['input'],
  ) => {
    updateAppointmentDetails({
      variables: {
        tenantId,
        id: appointmentId,
        input,
      },
    });
  };

  return (
    <DetailsSidebar title="Details">
      <VStack className="p-4">
        <PatientSelector
          disabled={isReadonly}
          tenantId={tenantId}
          onPatientSelected={(patientId) =>
            handleChange({ patientId: patientId ?? null })
          }
        />
        <Box vStack className="w-full gap-4 px-2 pt-4 lg:px-0">
          <div className="px-2 lg:px-0">
            <H4>Appointment</H4>
          </div>
          <Box className="grid w-full grid-cols-[auto] items-center gap-4 text-sm lg:grid-cols-[max-content_1fr]">
            <span className="px-2 text-gray-10 lg:px-0">Date</span>
            <SingleDateSelector
              disabled={isReadonly}
              date={toDate(appointmentDetails?.startDate)}
              onDateChange={(newDate) =>
                handleChange({ startDate: dateToISOString(newDate) })
              }
              variant="subtle"
            />

            <span className="px-2 text-gray-10 lg:px-0">Time</span>
            <TimeRangeSelect
              disabled={isReadonly}
              date={toDate(appointmentDetails?.startDate)}
              startTime={toDate(appointmentDetails?.startDate)}
              endTime={toDate(appointmentDetails?.endDate)}
              onStartTimeChange={(newTime) => {
                handleChange({ startDate: dateToISOString(newTime) });
              }}
              onEndTimeChange={(newTime) =>
                handleChange({ endDate: dateToISOString(newTime) })
              }
              variant="subtle"
            />

            <span className="px-2 text-gray-10 lg:px-0">Location</span>
            <LocationSelector
              disabled={isReadonly}
              tenantId={tenantId}
              selectedLocationId={appointmentDetails?.locationId ?? undefined}
              onLocationSelected={(location) =>
                handleChange({ locationId: location?.id ?? null })
              }
              variant="subtle"
            />

            <FeatureFlaggedComponent flag="SESSION_TYPES">
              <span className="mb-2 px-2 text-gray-10 lg:px-0">Type</span>
              <SessionTypeSelector disabled={isReadonly} />
            </FeatureFlaggedComponent>

            <span className="px-2 text-gray-10 lg:px-0">Owner</span>
            <Box
              hStack
              className="w-full overflow-hidden px-2"
              title={username}
            >
              <UserIcon size={16} />
              <p className="flex-1 truncate text-left text-sm">{username}</p>
            </Box>
          </Box>
        </Box>

        {status !== 'NOT_STARTED' && (
          <>
            <div className="flex-1" />
            <EhrDataImporter onlyShowIfError />
          </>
        )}
      </VStack>
    </DetailsSidebar>
  );
};
